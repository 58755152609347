export const scrollbar = {
  '&::-webkit-scrollbar': {
    height: 8,
    width: 8,
  },

  '&::-webkit-scrollbar-button:end:increment, &::-webkit-scrollbar-button:start:decrement': {
    background: 'transparent',
    display: 'none',
  },

  '&::-webkit-scrollbar-track-piece': {
    background: 'rgba(9, 30, 66, 0.08)',
  },

  '&::-webkit-scrollbar-track-piece:vertical:start': {
    borderRadius: '4px 4px 0 0',
  },

  '&::-webkit-scrollbar-track-piece:vertical:end': {
    borderRadius: '0 0 4px 4px',
  },

  '&::-webkit-scrollbar-track-piece:horizontal:start': {
    borderRadius: '4px 0 0 4px',
  },

  '&::-webkit-scrollbar-track-piece:horizontal:end': {
    borderRadius: '0 4px 4px 0',
  },

  '&::-webkit-scrollbar-thumb:horizontal, &::-webkit-scrollbar-thumb:vertical': {
    background: 'rgba(9, 30, 66, 0.13)',
    borderRadius: 4,
    display: 'block',
    height: 48,
  },
};
